import AbortService from "@rmp/core/services/abortService";
import { ApiCreateUser } from "@rmp/id/api/types/auth/apiCreateUser";
import { ApiForgotPasswordParameters } from "@rmp/id/api/types/auth/apiForgotPasswordParameters";
import { ApiAuthorizeResponse } from "@rmp/id/api/types/auth/apiAuthorizeResponse";
import { ApiLoginUser } from "@rmp/id/api/types/auth/apiLoginUser";
import BaseAuthController from "@rmp/id/api/base/baseAuthController";
import { urls } from "@rmp/id/api/config/urls";
import { resolveUrl } from "@rmp/core/utils/api";
const authAppBaseUrl = process.env.VUE_APP_AUTH_BASE_API_URL!;

export class AuthAppController extends BaseAuthController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	registerUser = async (payload: ApiCreateUser): Promise<void> => {
		try {
			await this.post<void>(urls.auth.register, {}, payload);
		} catch (e) {
			throw e;
		}
	};
	
	forgotPassword = async (payload: ApiForgotPasswordParameters): Promise<void> => {
		await this.post<void>(urls.auth.forgotPassword, {}, payload);
	};
	
	resetPassword = async (password: string): Promise<void> => {
		const query = window.location.search;
		const url = resolveUrl(authAppBaseUrl, this.urlsTree, urls.auth.resetPassword, {})
		
		await this.client.post<void>(`${url}${query}`, {password});
	};
	
	login = async (payload: ApiLoginUser): Promise<ApiAuthorizeResponse> => {
		const query = window.location.search;
		const url = resolveUrl(authAppBaseUrl, this.urlsTree, urls.auth.login, {});
		
		return await this.client.post<ApiAuthorizeResponse>(`${url}${query}`, payload, {
			withCredentials: true
		});
	};
	
	authorize = async (url: string): Promise<any> => {
		let { redirectUri } = await this.client.get<{ redirectUri: string }>(`${url}`, {
			withCredentials: true
		});
		
		return redirectUri;
	};
	
	signInWithProfile = async (payload: any): Promise<any> => {
		const query = window.location.search;
		const url = resolveUrl(authAppBaseUrl, this.urlsTree, urls.connect.signInWithProfile, {});
		
		let { redirectUri } = await this.client.post<any>(`${url}${query}`, payload, {
			withCredentials: true
		});
		
		return redirectUri;
	};
}
