import TreeModel from "tree-model";
import Route from "@rmp/core/router/route";
import { BaseRouteNames } from "@rmp/core/router/routeNames";

enum IdRouteNames {
	MASTER_ACCOUNT_ACCESS = "MASTER_ACCOUNT_ACCESS",
	SIGN_IN = "SIGN_IN",
	FORGOT_PASSWORD = "FORGOT_PASSWORD",
	RESET_PASSWORD = "RESET_PASSWORD",
	SELECT_PROFILE = "SELECT_PROFILE",
}

export const RouteNames = {
	...BaseRouteNames,
	...IdRouteNames
};

export type RouteNames = typeof RouteNames[keyof typeof RouteNames];

const tree = new TreeModel();

export const findRoute = (routeName: string, route = routesThreeRoot) => {
	return route.first(x => x.model.name === routeName);
};

export const findAllByAncestor = (ancestorRouteName: string) => {
	let searchNode: any = findRoute(ancestorRouteName);
	return routesThreeRoot.all(x => x.getPath().includes(searchNode));
};


export const routesThreeRoot = tree.parse<Route>(
	new Route(RouteNames.ROOT, [
		new Route(RouteNames.SIGN_IN),
		new Route(RouteNames.FORGOT_PASSWORD),
		new Route(RouteNames.RESET_PASSWORD),
		new Route(RouteNames.SELECT_PROFILE),
		new Route(RouteNames.MASTER_ACCOUNT_ACCESS)
	]));
