import Vue from "vue";
import VueRouter from "vue-router";
import { RouteNames } from "@rmp/id/router/routes";
import { configureMiddleware } from "@rmp/id/router/middleware";
import RmpAuthLayout from "@rmp/id/views/layouts/RmpAuthLayout.vue";
import RmpSignIn from "@rmp/id/views/signIn/RmpSignIn.vue";
import RmpForgotPassword from "@rmp/id/views/forgotPassword/RmpForgotPassword.vue";
import RmpResetPassword from "@rmp/id/views/resetPassword/RmpResetPassword.vue";
import RmpSelectProfile from "@rmp/id/views/selectProfile/RmpSelectProfile.vue";
import RmpEmptyLayout from "@rmp/core/components/layouts/RmpEmptyLayout.vue";
import RmpMasterAccountAccess from "@rmp/id/views/masterAccountAccess/RmpMasterAccountAccess.vue";
import RmpInternalServerError from "@rmp/core/pages/errors/RmpInternalServerError.vue";
import RmpAccessDeniedError from "@rmp/core/pages/errors/RmpAccessDeniedError.vue";
import RmpPageNotFound from "@rmp/core/pages/errors/RmpPageNotFound.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		component: RmpAuthLayout,
		name: RouteNames.ROOT,
		redirect: { name: RouteNames.SIGN_IN },
		children: [
			{
				path: "sign-in",
				component: RmpSignIn,
				name: RouteNames.SIGN_IN,
				meta: { requiresRedirectUri: true }
			},
			{
				path: "forgot-password",
				component: RmpForgotPassword,
				name: RouteNames.FORGOT_PASSWORD
			},
			{
				path: "reset-password",
				component: RmpResetPassword,
				name: RouteNames.RESET_PASSWORD
			},
			{
				path: "select-profile",
				component: RmpSelectProfile,
				name: RouteNames.SELECT_PROFILE
			}
		]
	},
	{
		path: "/",
		component: RmpEmptyLayout,
		children: [
			{
				path: "",
				component: RmpAuthLayout,
				children: [
					{
						path: "master-account-access",
						name: RouteNames.MASTER_ACCOUNT_ACCESS,
						component: RmpMasterAccountAccess
					}
				]
			},
			{
				path: "internal-server-error",
				component: RmpInternalServerError,
				name: RouteNames.ERROR_INTERNAL_SERVER_ERROR
			},
			{
				path: "access-denied",
				component: RmpAccessDeniedError,
				name: RouteNames.ERROR_ACCESS_DENIED
			},
			{
				path: "*",
				component: RmpPageNotFound,
				name: RouteNames.ERROR_PAGE_NOT_FOUND
			}
		]
	}
];

const router = new VueRouter({
	routes,
	mode: "history"
});

configureMiddleware(router);

export default router;
