export interface DocumentsType {
	inn: string | null;
	order: string | null;
}

export class DocumentsTypeHelper {
	static getEmpty(): DocumentsType {
		return {
			inn: null,
			order: null
		};
	}
}
