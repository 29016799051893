import { IMiddleware } from "@rmp/core/types/core/middleware";
import { Route } from "vue-router/types/router";
import { delay } from "@rmp/core/utils/delay";

const baseAppUrl = process.env.VUE_APP_BASE_APP_URL!;
const appsPath = "apps";

export default class SignInMiddleware implements IMiddleware {
	async invoke(to: Route) {
		if(!to.query.redirect_uri) {
			//@ts-ignore
			window.location = `${baseAppUrl}/${appsPath}`;
			
			// TODO временное решение, для избежания рендера страницы
			await delay(1000)
		} else
			return;
	}
}
