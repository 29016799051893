import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { BaseStepState } from "@rmp/id/stores/masterAccountAccess/steps/shared/baseStepState";
import { MasterAccountApplicationStepOrdersEnum } from "@rmp/id/stores/masterAccountAccess/types/MasterAccountApplicationStepOrdersEnum";

const abortService = new AbortService();

const page = usePageStore(abortService);

export interface MasterAccountAccessFinalStepState extends BaseStepState, PageState {
	formValid: boolean;
}

const getDefaultState = (): MasterAccountAccessFinalStepState => {
	return {
		...page.getDefaultPageState(),
		formValid: false,
		order: MasterAccountApplicationStepOrdersEnum.FINAL_STEP_ORDER
	};
};

export const useMasterAccountAccessFinalStepStore = defineStore({
	id: "master-account-access-final-step",
	state: (): MasterAccountAccessFinalStepState => getDefaultState(),
	getters: {
		...page.getters,
		stepCompleted(state: MasterAccountAccessFinalStepState): boolean {
			return true;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
		}
	}
});
