import "reflect-metadata";
import Vue from "vue";
import App from "@rmp/id/App.vue";
import router from "@rmp/id/router";
import { i18n, vuetify } from "@rmp/id/plugins";
import "@rmp/core/assets/main.css";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import { createPinia, PiniaVuePlugin } from "pinia";

Vue.use(VueTelInputVuetify, {
	vuetify
});

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.config.productionTip = false;

new Vue({
	i18n,
	router,
	vuetify,
	pinia,
	render: (h) => h(App)
}).$mount("#app");
