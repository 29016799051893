import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { BaseStepState } from "@rmp/id/stores/masterAccountAccess/steps/shared/baseStepState";
import { MasterAccountApplicationStepOrdersEnum } from "@rmp/id/stores/masterAccountAccess/types/MasterAccountApplicationStepOrdersEnum";
import { useMasterAccountAccessSelectCounterpartyStepStore } from "@rmp/id/stores/masterAccountAccess/steps/selectCounterparty";
import { CounterpartyTypeEnum } from "@rmp/core/types/CounterpartyTypeEnum";

const abortService = new AbortService();

const page = usePageStore(abortService);

export interface MasterAccountAccessSignApplicationStepState extends BaseStepState, PageState {
	encodedDetachedSignatureString: string;
	encodedApplicationString: string;	
}

const getDefaultState = (): MasterAccountAccessSignApplicationStepState => {
	return {
		...page.getDefaultPageState(),
		encodedDetachedSignatureString: "",
		encodedApplicationString: "",
		order: MasterAccountApplicationStepOrdersEnum.SIGN_APPLICATION_STEP_ORDER
	};
};

export const useMasterAccountAccessSignApplicationStepStore = defineStore({
	id: "master-account-access-sign-application-step",
	state: (): MasterAccountAccessSignApplicationStepState => getDefaultState(),
	getters: {
		...page.getters,
		stepCompleted(state: MasterAccountAccessSignApplicationStepState): boolean  {
			const { counterpartyType } = useMasterAccountAccessSelectCounterpartyStepStore();
			
			if(counterpartyType === CounterpartyTypeEnum.LEGAL_ENTITY) {
				return this.signed;
			} else {
				return true;
			}
		},
		signed(state: MasterAccountAccessSignApplicationStepState): boolean {
			return !!state.encodedApplicationString && !!state.encodedDetachedSignatureString;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {},
	}
});
