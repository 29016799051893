import { ApiLegalEntity } from "@rmp/core/api/types/legalEntity/apiLegalEntity";

export default class ApiLegalEntityMasterAccountAccessFromEmployeeApplicationCreate {
	email: string;
	legalEntity: ApiLegalEntity;

	constructor(email: string, legalEntity: ApiLegalEntity) {
		this.email = email;
		this.legalEntity = legalEntity;
	}
}
