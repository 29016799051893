import Middleware from "@rmp/core/router/middleware/middleware";
import { NavigationGuardNext, Route, VueRouter } from "vue-router/types/router";
import SignInMiddleware from "@rmp/id/router/middleware/items/signInMiddleware";
import { useAppStore } from "@rmp/core/stores/appStore";

export const configureMiddleware = (router: VueRouter) => {
	router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
		const middleware = new Middleware(to, from, next);
		
		const isRequiresRedirectUri = to.matched.some(record => record.meta.requiresRedirectUri);
		
		if(isRequiresRedirectUri) {
			middleware.useMiddleware(SignInMiddleware);
		}
		
		await middleware.run();
		
		const appStore = useAppStore();
		appStore.setMiddlewareExecuted(false);
	});
};
