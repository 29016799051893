import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { BaseStepState } from "@rmp/id/stores/masterAccountAccess/steps/shared/baseStepState";
import { MasterAccountApplicationStepOrdersEnum } from "@rmp/id/stores/masterAccountAccess/types/MasterAccountApplicationStepOrdersEnum";
import { ApiLegalEntity } from "@rmp/core/api/types/legalEntity/apiLegalEntity";
import { ApiFnsLegalPerson } from "@rmp/core/api/types/fns/apiFnsLegalPerson";
import { CounterpartyTypeEnum } from "@rmp/core/types/CounterpartyTypeEnum";
import { MasterAccountAccessErrorTypeEnum } from "@rmp/id/stores/masterAccountAccess/types/MasterAccountAccessErrorTypeEnum";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import alertService from "@rmp/core/stores/alerts/services/alertService";
import MasterAccountAccessApplicationStatus from "@rmp/core/api/types/masterAccountAccessApplication/masterAccountAccessApplicationStatus";
import { LegalEntityController } from "@rmp/core/api/counterparty/legalEntity";
import { LegalPersonController } from "@rmp/core/api/counterparty/legalPerson";
import { MasterAccountAccessApplicationController } from "@rmp/core/api/masterAccountAccessApplication";
import { i18n } from "@rmp/core/plugins";

const abortService = new AbortService();
const legalEntityController = new LegalEntityController(abortService);
const legalPersonController = new LegalPersonController(abortService);
const masterAccountAccessApplicationController = new MasterAccountAccessApplicationController(abortService);

const page = usePageStore(abortService);

export interface MasterAccountAccessSelectCounterpartyStepState extends BaseStepState, PageState {
	inn: string;
	counterparty: ApiLegalEntity | ApiFnsLegalPerson | null;
	counterpartyType: CounterpartyTypeEnum;
	counterpartyLoading: boolean;
	counterpartyAlreadyExists: boolean;
	masterAccountAlreadyExists: boolean;
	errors: { type: MasterAccountAccessErrorTypeEnum }[];
}

const getDefaultState = (): MasterAccountAccessSelectCounterpartyStepState => {
	return {
		...page.getDefaultPageState(),
		order: MasterAccountApplicationStepOrdersEnum.SELECT_COUNTERPARTY_STEP_ORDER,
		inn: "",
		counterparty: null,
		counterpartyType: CounterpartyTypeEnum.UNKNOWN,
		counterpartyLoading: false,
		counterpartyAlreadyExists: false,
		masterAccountAlreadyExists: false,
		errors: []
	};
};

export const useMasterAccountAccessSelectCounterpartyStepStore = defineStore({
	id: "master-account-access-select-counterparty-step",
	state: (): MasterAccountAccessSelectCounterpartyStepState => getDefaultState(),
	getters: {
		...page.getters,
		stepCompleted(state: MasterAccountAccessSelectCounterpartyStepState) {
			if(state.counterpartyLoading || state.errors.length > 0) return false;
			
			return !!state.counterparty;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
		},
		resetFetchedInfo() {
			this.counterparty = null;
			this.counterpartyType = CounterpartyTypeEnum.UNKNOWN;
			this.counterpartyAlreadyExists = false;
			this.masterAccountAlreadyExists = false;
			this.errors = [];
		},
		async fetchCounterparty() {
			this.resetFetchedInfo();
			
			this.counterpartyLoading = true;
			
			switch (this.inn.length) {
				case 10:
					this.counterpartyType = CounterpartyTypeEnum.LEGAL_ENTITY;
					break;
				case 12:
					this.counterpartyType = CounterpartyTypeEnum.LEGAL_PERSON;
					break;
			}
			
			try {
				if(this.counterpartyType === CounterpartyTypeEnum.LEGAL_ENTITY) {
					const suggestion = await legalEntityController.getLegalEntitySuggestion(this.inn);
					
					if(!suggestion) {
						alertService.addCustomError(i18n.t("alerts.errors.legalEntityByInnNotFound", { inn: this.inn }) as string);
						return;
					}
					
					this.counterparty = suggestion;
					
					const { inn, ogrn, kpp } = suggestion;
					
					// Существует ли контрагент в системе
					const counterpartyExists = await legalEntityController.checkLegalEntityCounterpartyIsExists(inn, ogrn, kpp);
					if(counterpartyExists) {
						this.counterpartyAlreadyExists = true;
						
						// Есть ли в системе мастер-аккаунт у этого контрагента
						this.masterAccountAlreadyExists = await legalEntityController.checkLegalEntityProfile(inn, ogrn, kpp);
					}
					
					// Проверяем наличие заявки в системе
					const applicationExists = await masterAccountAccessApplicationController.getLegalEntityAccountApplicationId(inn,
						ogrn,
						kpp,
						MasterAccountAccessApplicationStatus.Created);
					
					// Если заявка уже есть, то новая подана быть не может
					if(applicationExists) {
						this.errors.push({ type: MasterAccountAccessErrorTypeEnum.APPLICATION_EXISTS });
					}
				} else if(this.counterpartyType === CounterpartyTypeEnum.LEGAL_PERSON) {
					const suggestion = await legalPersonController.getSuggestion(this.inn);
					
					if(!suggestion) {
						alertService.addCustomError(i18n.t("alerts.errors.legalPersonByInnNotFound", { inn: this.inn }) as string);
						return;
					}
					
					this.counterparty = suggestion;
					
					const { inn, ogrn } = suggestion;
					
					// Существует ли контрагент в системе
					const counterpartyExists = await legalPersonController.checkLegalPersonIsExists(inn, ogrn);
					if(counterpartyExists) {
						this.counterpartyAlreadyExists = true;
						
						// Есть ли в системе мастер-аккаунт у этого контрагента
						this.masterAccountAlreadyExists = await legalPersonController.checkMasterAccount(inn, ogrn);
					}
					
					// Проверяем наличие заявки в системе
					const applicationExists = await masterAccountAccessApplicationController.getLegalPersonAccountApplicationId(inn, ogrn,
						MasterAccountAccessApplicationStatus.Created);
					
					// Если заявка уже есть, то новая подана быть не может
					if(applicationExists) {
						this.errors.push({ type: MasterAccountAccessErrorTypeEnum.APPLICATION_EXISTS });
					}
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.counterpartyLoading = false;
			}
		}
	}
});
