import { MasterAccountApplicationStepOrdersEnum } from "@rmp/id/stores/masterAccountAccess/types/MasterAccountApplicationStepOrdersEnum";

export default {
	fields: {
		email: {
			label: "Ваш email",
			placeholder: "email@mail.ru"
		},
		password: {
			label: "Ваш пароль",
			placeholder: "Введите пароль"
		},
		confirmedPassword: {
			label: "Подтверждение пароля",
			placeholder: "Введите пароль"
		}
	},
	buttons: {
		registerOrganization: "Зарегистрировать организацию"
	},
	titles: {
		signUp: "Регистрация"
	},
	aliases: {
		masterAccountApplicationStep: {
			[MasterAccountApplicationStepOrdersEnum.SELECT_COUNTERPARTY_STEP_ORDER]: "Выбор организации",
			[MasterAccountApplicationStepOrdersEnum.CONTACT_INFORMATION_STEP_ORDER]: "Контактная информация",
			[MasterAccountApplicationStepOrdersEnum.SIGN_APPLICATION_STEP_ORDER]: "Подтверждение сведений об организации",
			[MasterAccountApplicationStepOrdersEnum.DOCUMENTS_STEP_ORDER]: "Документы",
			[MasterAccountApplicationStepOrdersEnum.FINAL_STEP_ORDER]: "Результаты рассмотрения заявки",
		}
	}
};
