export const urls = {
	auth: {
		path: "auth",
		register: {
			path: "register"
		},
		login: {
			path: "login"
		},
		logout: {
			path: "logout"
		},
		forgotPassword: {
			path: "forgot-password"
		},
		resetPassword: {
			path: "reset-password"
		}
	},
	connect: {
		path: "connect",
		signInWithProfile: {
			path: "sign-in-with-profile"
		},
		authorize: {
			path: "authorize"
		}
	}
};

