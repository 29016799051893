import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { AuthAppController } from "@rmp/id/api/auth";
import { AuthPageModeType } from "@rmp/core/types/authorization/authPageModeType";

const abortService = new AbortService();
const authAppController = new AuthAppController(abortService);

const page = usePageStore(abortService);
const form = useFormStore();

export interface ResetPasswordState extends PageState, FormState {
	password: string;
	mode: AuthPageModeType;
}

const getDefaultState = (): ResetPasswordState => {
	return {
		...page.getDefaultPageState(),
		...form.getDefaultState(),
		password: "",
		mode: AuthPageModeType.INITIAL
	};
};

export const useResetPasswordStore = defineStore({
	id: "id-reset-password",
	state: (): ResetPasswordState => getDefaultState(),
	getters: {
		...page.getters,
		...form.getters
	},
	actions: {
		...page.actions,
		...form.actions,
		async beforeInitialized() {
		},
		async save() {
			this.formSaving = true;

			try {
				await authAppController.resetPassword(this.password);

				this.mode = AuthPageModeType.SUCCESS;
			} catch (error: any) {
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.formSaving = false;
			}
		}
	}
});
