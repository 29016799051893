import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { AuthAppController } from "@rmp/id/api/auth";
import { ApiProfile } from "@rmp/id/api/types/auth/apiProfile";
import alertService from "@rmp/core/stores/alerts/services/alertService";
import { useSignInStore } from "@rmp/id/stores/signIn";

const abortService = new AbortService();
const authAppController = new AuthAppController(abortService);

const page = usePageStore(abortService);
const form = useFormStore();

export interface SelectProfileState extends PageState, FormState {
	profiles: ApiProfile[];
	selectedProfile: ApiProfile | null;
}

const getDefaultState = (): SelectProfileState => {
	return {
		...page.getDefaultPageState(),
		...form.getDefaultState(),
		profiles: [],
		selectedProfile: null
	};
};

export const useSelectProfileStore = defineStore({
	id: "select-profile",
	state: (): SelectProfileState => getDefaultState(),
	getters: {
		...page.getters,
		...form.getters
	},
	actions: {
		...page.actions,
		...form.actions,
		async beforeInitialized() {
			const { profiles } = useSignInStore();
			
			if(!profiles.length)
				// @ts-ignore
				window.location = process.env.VUE_APP_BASE_APP_URL;
			
			this.profiles = profiles;
			this.selectedProfile = profiles[0];
		},
		async submit({ id, payload }: { id: string, payload?: any }) {
			this.formSaving = true;

			try {
				let profile = this.profiles.find(x => x.id === id);
				
				if(!profile) throw new Error("profile");
				
				let redirectUri = await authAppController.signInWithProfile({
					profileId: profile.id,
					signedPayload: payload
				});
				
				window.location = await authAppController.authorize(redirectUri);
			} catch (error: any) {
				console.error(error);
				
				if(error.detail) {
					alertService.addCustomError(error.detail);
				} else {
					AlertHelper.handleGeneralRequestErrors(error);
				}
			} finally {
				this.formSaving = false;
			}
		}
	}
});
