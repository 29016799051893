import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { BaseStepState } from "@rmp/id/stores/masterAccountAccess/steps/shared/baseStepState";
import { MasterAccountApplicationStepOrdersEnum } from "@rmp/id/stores/masterAccountAccess/types/MasterAccountApplicationStepOrdersEnum";
import { DocumentsType, DocumentsTypeHelper } from "@rmp/core/api/types/documents/documentsType";
import { CounterpartyTypeEnum } from "@rmp/core/types/CounterpartyTypeEnum";
import { STORAGE_APPOINTMENT_HEAD_ORDER, STORAGE_INN_NAMESPACE } from "@rmp/core/constants/storage";
import { useMasterAccountAccessSelectCounterpartyStepStore } from "@rmp/id/stores/masterAccountAccess/steps/selectCounterparty";

const abortService = new AbortService();

const page = usePageStore(abortService);

export interface MasterAccountAccessDocumentsStepState extends BaseStepState, PageState {
	formValid: boolean;
	documents: DocumentsType;
}

const getDefaultState = (): MasterAccountAccessDocumentsStepState => {
	return {
		...page.getDefaultPageState(),
		order: MasterAccountApplicationStepOrdersEnum.DOCUMENTS_STEP_ORDER,
		formValid: false,
		documents: DocumentsTypeHelper.getEmpty()
	};
};

export const useMasterAccountAccessDocumentsStepStore = defineStore({
	id: "master-account-access-documents-step",
	state: (): MasterAccountAccessDocumentsStepState => getDefaultState(),
	getters: {
		...page.getters,
		stepCompleted(state: MasterAccountAccessDocumentsStepState): boolean {
			const { counterpartyType } = useMasterAccountAccessSelectCounterpartyStepStore();
			
			switch (counterpartyType) {
				case CounterpartyTypeEnum.LEGAL_ENTITY:
					return !!(state.documents.inn && state.documents.order);
				case CounterpartyTypeEnum.LEGAL_PERSON:
					return !!state.documents.inn;
				default:
					return false;
			}
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
		},
		async processFile({ fileId, docType }: { fileId: string, docType: string }) {
			switch (docType) {
				case STORAGE_INN_NAMESPACE:
					this.documents.inn = fileId;
					break;
				case STORAGE_APPOINTMENT_HEAD_ORDER:
					this.documents.order = fileId;
					break;
			}
		}
	}
});
