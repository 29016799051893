import AbortService from "@rmp/core/services/abortService";
import BaseController from "@rmp/core/api/shared/baseController";
import { ApiSegment, createUrlsTree, Node, resolveUrl } from "@rmp/core/utils/api";
import TreeModel from "tree-model";
import { urls } from "@rmp/id/api/config/urls";
const authAppBaseUrl = process.env.VUE_APP_AUTH_BASE_API_URL!;

export default class BaseAuthController extends BaseController {
	urlsTree: TreeModel.Node<Node>;
	
	constructor(abortService: AbortService) {
		super(abortService);
		
		this.urlsTree = createUrlsTree(urls);
	}
	
	async get<T>(segment: ApiSegment, params: object = {}, options?: object) {
		const url = resolveUrl(authAppBaseUrl, this.urlsTree, segment, params);
		
		return await this.client.get<T>(url);
	}
	
	async post<T>(segment: ApiSegment, params: object = {}, payload: object = {}, additionBaseUrlQuery: string = "", options?: object) {
		const url = resolveUrl(authAppBaseUrl, this.urlsTree, segment, params);
		const requestedUrl = additionBaseUrlQuery ? `${url}${additionBaseUrlQuery}` : url;
		
		return await this.client.post<T>(requestedUrl, payload);
	}
}
