import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { BaseStepState } from "@rmp/id/stores/masterAccountAccess/steps/shared/baseStepState";
import { MasterAccountApplicationStepOrdersEnum } from "@rmp/id/stores/masterAccountAccess/types/MasterAccountApplicationStepOrdersEnum";

const abortService = new AbortService();

const page = usePageStore(abortService);

export interface MasterAccountAccessContactInformationStepState extends BaseStepState, PageState {
	email: string;
	formValid: boolean;
}

const getDefaultState = (): MasterAccountAccessContactInformationStepState => {
	return {
		...page.getDefaultPageState(),
		email: "",
		formValid: false,
		order: MasterAccountApplicationStepOrdersEnum.CONTACT_INFORMATION_STEP_ORDER
	};
};

export const useMasterAccountAccessContactInformationStepStore = defineStore({
	id: "master-account-access-contact-information-step",
	state: (): MasterAccountAccessContactInformationStepState => getDefaultState(),
	getters: {
		...page.getters,
		stepCompleted(state: MasterAccountAccessContactInformationStepState) {
			return !!state.email && state.formValid;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {},
	}
});
