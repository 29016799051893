import { defineStore } from "pinia";
import AbortService from "@rmp/core/services/abortService";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { AuthAppController } from "@rmp/id/api/auth";
import { ApiProfile } from "@rmp/id/api/types/auth/apiProfile";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { ApiLoginUser } from "@rmp/id/api/types/auth/apiLoginUser";
import router from "@rmp/id/router";
import { RouteNames } from "@rmp/id/router/routes";

const abortService = new AbortService();
const authAppController = new AuthAppController(abortService);

const form = useFormStore();
const snapshotStore = useSnapshotStore([
	{ key: SnapshotKeysEnum.LAST_SAVED, fields: ["user"] }
]);

export interface SignInState extends SnapshotState, FormState {
	profiles: ApiProfile[];
	user: ApiLoginUser;
}

const getDefaultState = (): SignInState => {
	return {
		...form.getDefaultState(),
		...snapshotStore.getDefaultState(),
		profiles: [],
		user: {} as ApiLoginUser
	};
};

export const useSignInStore = defineStore({
	id: "sign-in",
	state: (): SignInState => getDefaultState(),
	getters: {
		...form.getters,
		...snapshotStore.getters
	},
	actions: {
		...form.actions,
		...snapshotStore.actions,
		async initialize() {
			abortService.initialize();
			
			this.$reset();
			this.setStateSnapshot();
		},
		async save() {
			this.formSaving = true;

			try {
				let { profiles, redirectUri } = await authAppController.login(this.user);
				
				let callbackUrl = await authAppController.authorize(redirectUri);
				
				switch (profiles.length) {
					case 0:
					{
						window.location = callbackUrl;
						break;
					}
					case 1:
					{
						let [profile] = profiles;
						if(!profile.isSignatureRequired) {
							let redirectUri = await authAppController.signInWithProfile({
								profileId: profile.id
							});
							
							window.location = await authAppController.authorize(redirectUri);
						} else {
							this.profiles = profiles;
							
							await router.push({ name: RouteNames.SELECT_PROFILE, query: router.currentRoute.query });
						}
						
						break;
					}
					default:
					{
						this.profiles = profiles;

						await router.push({ name: RouteNames.SELECT_PROFILE, query: router.currentRoute.query });
					}
				}
			} catch (error: any) {
				switch (error.constructor) {
					case HttpNotFoundException:
						alertService.addError(AlertKeys.ACCOUNT_NOT_FOUND_ERROR);
						break;
					default:
						console.error(error);
						AlertHelper.handleGeneralRequestErrors(error);
				}
			} finally {
				this.formSaving = false;
			}
		}
	}
});
