import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { MasterAccountApplicationMethodTypeEnum } from "@rmp/id/stores/masterAccountAccess/types/MasterAccountApplicationMethodTypeEnum";
import { MasterAccountAccessApplicationController } from "@rmp/core/api/masterAccountAccessApplication";
import { LegalEntityController } from "@rmp/core/api/counterparty/legalEntity";
import { LegalPersonController } from "@rmp/core/api/counterparty/legalPerson";
import { CounterpartyTypeEnum } from "@rmp/core/types/CounterpartyTypeEnum";
import { useMasterAccountAccessContactInformationStepStore } from "@rmp/id/stores/masterAccountAccess/steps/contactInformation";
import router from "@rmp/id/router";
import { MasterAccountApplicationStepOrdersEnum } from "@rmp/id/stores/masterAccountAccess/types/MasterAccountApplicationStepOrdersEnum";
import NotImplementedException from "@rmp/core/exceptions/notImplementedException";
import { useMasterAccountAccessSelectCounterpartyStepStore } from "@rmp/id/stores/masterAccountAccess/steps/selectCounterparty";
import { useMasterAccountAccessDocumentsStepStore } from "@rmp/id/stores/masterAccountAccess/steps/documents";
import { ApiLegalEntity } from "@rmp/core/api/types/legalEntity/apiLegalEntity";
import ApiLegalEntityIdentifiers from "@rmp/core/api/types/legalEntity/apiLegalEntityIdentifiers";
import ApiLegalEntityMasterAccountAccessFromEmployeeApplicationCreate
	from "@rmp/core/api/types/masterAccountAccessApplication/apiLegalEntityMasterAccountAccessFromEmployeeApplicationCreate";
import { ApiFnsLegalPerson } from "@rmp/core/api/types/fns/apiFnsLegalPerson";
import ApiLegalPersonIdentifiers from "@rmp/core/api/types/counterparty/apiLegalPersonIdentifiers";
import ApiLegalPersonMasterAccountAccessFromEmployeeApplicationCreate
	from "@rmp/core/api/types/masterAccountAccessApplication/apiLegalPersonMasterAccountAccessFromEmployeeApplicationCreate";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { ApiSignedMasterAccountAccessApplication }
	from "@rmp/core/api/types/masterAccountAccessApplication/apiSignedMasterAccountAccessApplication";
import { ApiLegalEntityMasterAccountAccessApplicationCreate }
	from "@rmp/core/api/types/masterAccountAccessApplication/apiLegalEntityMasterAccountAccessApplicationCreate";
import { ApiLegalPersonMasterAccountAccessApplicationCreate }
	from "@rmp/core/api/types/masterAccountAccessApplication/apiLegalPersonMasterAccountAccessApplicationCreate";
import { useMasterAccountAccessSignApplicationStepStore } from "@rmp/id/stores/masterAccountAccess/steps/signApplication";
import { useMasterAccountAccessFinalStepStore } from "@rmp/id/stores/masterAccountAccess/steps/final";

const abortService = new AbortService();
const masterAccountAccessApplicationController = new MasterAccountAccessApplicationController(abortService);
const legalEntityController = new LegalEntityController(abortService);
const legalPersonController = new LegalPersonController(abortService);

const page = usePageStore(abortService);

export interface MasterAccountAccessState extends PageState {
	currentStep: MasterAccountApplicationStepOrdersEnum;
	createMasterAccountRequestOperationExecuting: boolean;
	applicationMethod: MasterAccountApplicationMethodTypeEnum;
}

const getDefaultState = (): MasterAccountAccessState => {
	return {
		...page.getDefaultPageState(),
		currentStep: MasterAccountApplicationStepOrdersEnum.SELECT_COUNTERPARTY_STEP_ORDER,
		createMasterAccountRequestOperationExecuting: false,
		applicationMethod: MasterAccountApplicationMethodTypeEnum.UNKNOWN
	};
};

export const useMasterAccountAccessStore = defineStore({
	id: "id-master-account-access",
	state: (): MasterAccountAccessState => getDefaultState(),
	getters: {
		...page.getters,
		steps(): MasterAccountApplicationStepOrdersEnum[] {
			return [
				MasterAccountApplicationStepOrdersEnum.SELECT_COUNTERPARTY_STEP_ORDER,
				MasterAccountApplicationStepOrdersEnum.CONTACT_INFORMATION_STEP_ORDER,
				MasterAccountApplicationStepOrdersEnum.SIGN_APPLICATION_STEP_ORDER,
				MasterAccountApplicationStepOrdersEnum.DOCUMENTS_STEP_ORDER,
				MasterAccountApplicationStepOrdersEnum.FINAL_STEP_ORDER
			];
		},
		nextStepAvailable(state: MasterAccountAccessState): boolean {
			switch (state.currentStep) {
				case MasterAccountApplicationStepOrdersEnum.SELECT_COUNTERPARTY_STEP_ORDER:
				{
					const { stepCompleted } = useMasterAccountAccessSelectCounterpartyStepStore();
					return stepCompleted;
				}
				case MasterAccountApplicationStepOrdersEnum.CONTACT_INFORMATION_STEP_ORDER:
				{
					const { stepCompleted } = useMasterAccountAccessContactInformationStepStore();
					return stepCompleted;
				}
				case MasterAccountApplicationStepOrdersEnum.SIGN_APPLICATION_STEP_ORDER:
				{
					const { stepCompleted } = useMasterAccountAccessSignApplicationStepStore();
					return stepCompleted;
				}
				case MasterAccountApplicationStepOrdersEnum.DOCUMENTS_STEP_ORDER:
				{
					const { stepCompleted } = useMasterAccountAccessDocumentsStepStore();
					return stepCompleted;
				}
				case MasterAccountApplicationStepOrdersEnum.FINAL_STEP_ORDER:
				{
					const { stepCompleted } = useMasterAccountAccessFinalStepStore();
					return stepCompleted;
				}
				default:
					throw new NotImplementedException("nextStepAvailable");
			}
		},
		prevStepAvailable(): boolean {
			return this.currentStepIndex !== 0;
		},
		currentStepIndex(): number {
			return this.steps.indexOf(this.currentStep);
		},
		firstStep(): boolean {
			return this.currentStep === MasterAccountApplicationStepOrdersEnum.SELECT_COUNTERPARTY_STEP_ORDER;
		},
		finalStep(): boolean {
			return this.currentStep === MasterAccountApplicationStepOrdersEnum.FINAL_STEP_ORDER;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
		},
		moveToNextStep() {
			this.currentStep = this.steps[this.currentStepIndex + 1];
		},
		async handleNextStep() {
			switch (this.currentStep) {
				case MasterAccountApplicationStepOrdersEnum.CONTACT_INFORMATION_STEP_ORDER:
				{
					const { masterAccountAlreadyExists } = useMasterAccountAccessSelectCounterpartyStepStore();
					
					if(masterAccountAlreadyExists) {
						let result = await this.tryCreateApplicationByEmployeeEmail();
						
						if(result) {
							this.applicationMethod = MasterAccountApplicationMethodTypeEnum.BY_EMPLOYEE;
							
							return this.moveToFinalStep();
						}
					}
					
					return this.moveToNextStep();
				}
				case MasterAccountApplicationStepOrdersEnum.SIGN_APPLICATION_STEP_ORDER:
				{
					const { counterpartyType } = useMasterAccountAccessSelectCounterpartyStepStore();
					
					if(counterpartyType === CounterpartyTypeEnum.LEGAL_ENTITY) {
						let result = await this.tryCreateSignedApplication();
						
						if(result) {
							this.applicationMethod = MasterAccountApplicationMethodTypeEnum.BY_SIGNATURE;
							
							return this.moveToFinalStep();
						} else {
							return;
						}
					} else {
						return this.moveToNextStep();
					}
				}
				case MasterAccountApplicationStepOrdersEnum.DOCUMENTS_STEP_ORDER:
				{
					let result = await this.tryCreateApplicationByDocuments();
					
					if(result) {
						this.applicationMethod = MasterAccountApplicationMethodTypeEnum.BY_DOCUMENTS;
						
						return this.moveToFinalStep();
					} else {
						return;
					}
				}
				default:
					return this.moveToNextStep();
			}
		},
		moveToPrevStep() {
			this.currentStep = this.steps[this.currentStepIndex - 1];
		},
		async handlePrevStep() {
			switch (this.currentStep) {
				case MasterAccountApplicationStepOrdersEnum.SELECT_COUNTERPARTY_STEP_ORDER:
				{
					this.destroy();
					await router.go(-1);
					break;
				}
				default:
					this.moveToPrevStep();
					break;
			}
		},
		moveToFinalStep() {
			this.currentStep = MasterAccountApplicationStepOrdersEnum.FINAL_STEP_ORDER;
		},
		moveToDocumentsStep() {
			this.currentStep = MasterAccountApplicationStepOrdersEnum.DOCUMENTS_STEP_ORDER;
		},
		async tryCreateApplicationByEmployeeEmail() {
			this.createMasterAccountRequestOperationExecuting = true;
			
			let result = false;
			
			try {
				const { email } = useMasterAccountAccessContactInformationStepStore();
				const { counterparty, counterpartyType } = useMasterAccountAccessSelectCounterpartyStepStore();
				
				if(counterpartyType === CounterpartyTypeEnum.LEGAL_ENTITY) {
					const legalEntity = counterparty as ApiLegalEntity;
					const identifiers = new ApiLegalEntityIdentifiers(legalEntity.inn, legalEntity.ogrn, legalEntity.kpp);
					let employeeExists = await legalEntityController.checkEmployeeExists(identifiers, email);
					
					if(employeeExists) {
						await masterAccountAccessApplicationController.submitLegalEntityAccessFromEmployeeApplication(
							new ApiLegalEntityMasterAccountAccessFromEmployeeApplicationCreate(email, counterparty as ApiLegalEntity)
						);
						
						result = true;
					}
				} else {
					const legalPerson = counterparty as ApiFnsLegalPerson;
					const identifiers = new ApiLegalPersonIdentifiers(legalPerson.inn, legalPerson.ogrn);
					let employeeExists = await legalPersonController.checkEmployeeExists(identifiers, email);
					
					if(employeeExists) {
						await masterAccountAccessApplicationController.submitLegalPersonAccessFromEmployeeApplication(
							new ApiLegalPersonMasterAccountAccessFromEmployeeApplicationCreate(
								email, counterparty as ApiFnsLegalPerson
							)
						);
						
						result = true;
					}
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.createMasterAccountRequestOperationExecuting = false;
			}
			
			return result;
		},
		async tryCreateSignedApplication() {
			this.createMasterAccountRequestOperationExecuting = true;
			
			let result = false;
			
			try {
				const { encodedApplicationString, encodedDetachedSignatureString } = useMasterAccountAccessSignApplicationStepStore();
				
				const payload = {
					data: encodedApplicationString,
					signature: encodedDetachedSignatureString
				} as ApiSignedMasterAccountAccessApplication;
				
				await masterAccountAccessApplicationController.submitSignedLegalEntityAccessApplication(payload);
				
				result = true;
			} catch (e) {
				console.error(e);
				AlertHelper.handleGeneralRequestErrors(e);
			} finally {
				this.createMasterAccountRequestOperationExecuting = false;
			}
			
			return result;
		},
		async tryCreateApplicationByDocuments() {
			this.createMasterAccountRequestOperationExecuting = true;
			
			let result = false;
			
			try {
				const { documents } = useMasterAccountAccessDocumentsStepStore();
				const { email } = useMasterAccountAccessContactInformationStepStore();
				const { counterparty, counterpartyType } = useMasterAccountAccessSelectCounterpartyStepStore();
				
				if(counterpartyType === CounterpartyTypeEnum.LEGAL_ENTITY) {
					const payload = {
						email,
						legalEntity: counterparty as ApiLegalEntity,
						documents: Object.values(documents).filter(x => Boolean(x))
					} as ApiLegalEntityMasterAccountAccessApplicationCreate;
					
					await masterAccountAccessApplicationController.submitLegalEntityMasterAccountAccessApplication(payload);
				} else {
					const payload = {
						email,
						entrepreneur: counterparty as ApiFnsLegalPerson,
						documents: Object.values(documents).filter(x => Boolean(x))
					} as ApiLegalPersonMasterAccountAccessApplicationCreate;
					
					await masterAccountAccessApplicationController.submitLegalPersonMasterAccountAccessApplication(payload);
				}
				
				result = true;
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.createMasterAccountRequestOperationExecuting = false;
			}
			
			return result;
		}
	}
});
