import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { AuthAppController } from "@rmp/id/api/auth";
import { ApiForgotPasswordParameters } from "@rmp/id/api/types/auth/apiForgotPasswordParameters";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import { AuthPageModeType } from "@rmp/core/types/authorization/authPageModeType";

const abortService = new AbortService();
const authAppController = new AuthAppController(abortService);

const page = usePageStore(abortService);
const form = useFormStore();

export interface ForgotPasswordState extends PageState, FormState {
	email: string;
	mode: AuthPageModeType;
}

const getDefaultState = (): ForgotPasswordState => {
	return {
		...page.getDefaultPageState(),
		...form.getDefaultState(),
		email: "",
		mode: AuthPageModeType.INITIAL
	};
};

export const useForgotPasswordStore = defineStore({
	id: "forgot-password",
	state: (): ForgotPasswordState => getDefaultState(),
	getters: {
		...page.getters,
		...form.getters,
	},
	actions: {
		...page.actions,
		...form.actions,
		async beforeInitialized() {
		},
		async save() {
			this.formSaving = true;

			try {
				await authAppController.forgotPassword({
					email: this.email
				} as ApiForgotPasswordParameters);
				
				this.mode = AuthPageModeType.SUCCESS;
			} catch (error: any) {
				console.error(error);
				
				switch (error.constructor) {
					case HttpNotFoundException:
						break;
					default:
						AlertHelper.handleGeneralRequestErrors(error);
				}
			} finally {
				this.formSaving = false;
			}
		}
	}
});
