import urls, { urlTemplateParts } from "@rmp/core/api/config/urls";
import { prepareUrl } from "@rmp/core/utils/prepareUrlQuery";
import { plainToInstance } from "class-transformer";
import { ApiLegalEntityMasterAccountAccessApplicationCreate }
	from "@rmp/core/api/types/masterAccountAccessApplication/apiLegalEntityMasterAccountAccessApplicationCreate";
import MasterAccountAccessApplicationStatus from "@rmp/core/api/types/masterAccountAccessApplication/masterAccountAccessApplicationStatus";
import ApiGetMasterAccountAccessApplicationsParameters
	from "@rmp/core/api/types/masterAccountAccessApplication/apiGetMasterAccountAccessApplicationsParameters";
import ApiMasterAccountAccessApplications from "@rmp/core/api/types/masterAccountAccessApplication/apiMasterAccountAccessApplications";
import ApiMasterAccountAccessApplication from "@rmp/core/api/types/masterAccountAccessApplication/apiMasterAccountAccessApplication";
import { ApiSignedMasterAccountAccessApplication } from "@rmp/core/api/types/masterAccountAccessApplication/apiSignedMasterAccountAccessApplication";
import { ApiLegalPersonMasterAccountAccessApplicationCreate }
	from "@rmp/core/api/types/masterAccountAccessApplication/apiLegalPersonMasterAccountAccessApplicationCreate";
import BaseController from "@rmp/core/api/shared/baseController";
import AbortService from "@rmp/core/services/abortService";
import ApiLegalEntityMasterAccountAccessFromEmployeeApplicationCreate
	from "@rmp/core/api/types/masterAccountAccessApplication/apiLegalEntityMasterAccountAccessFromEmployeeApplicationCreate";
import ApiLegalPersonMasterAccountAccessFromEmployeeApplicationCreate
	from "@rmp/core/api/types/masterAccountAccessApplication/apiLegalPersonMasterAccountAccessFromEmployeeApplicationCreate";

export class MasterAccountAccessApplicationController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getMasterAccountAccessApplications = async (parameters: ApiGetMasterAccountAccessApplicationsParameters): Promise<ApiMasterAccountAccessApplications> => {
		const url = prepareUrl(urls.applications.masterAccountAccess.getMasterAccountAccessApplications, parameters);
		let data = await this.client.get<{ requests: object[], totalCount: number }>(url, {});

		return new ApiMasterAccountAccessApplications(plainToInstance(ApiMasterAccountAccessApplication, data.requests), data.totalCount);
	};

	approveMasterAccountAccessApplication = async (id: string, status: MasterAccountAccessApplicationStatus): Promise<void> => {
		await this.client.put<ApiMasterAccountAccessApplication>(urls.applications.masterAccountAccess.approveApplication.replace(urlTemplateParts.id, id), {status});
	};
	declineMasterAccountAccessApplication = async (id: string, status: MasterAccountAccessApplicationStatus, rejectReason: string): Promise<void> => {
		await this.client.put<ApiMasterAccountAccessApplication>(urls.applications.masterAccountAccess.rejectApplication.replace(urlTemplateParts.id, id), { status, rejectReason });
	};

	submitLegalEntityMasterAccountAccessApplication = async (payload: ApiLegalEntityMasterAccountAccessApplicationCreate) => {
		return await this.client.post(urls.applications.masterAccountAccess.createLegalEntityAccountApplication, payload);
	};

	submitLegalPersonMasterAccountAccessApplication = async (payload: ApiLegalPersonMasterAccountAccessApplicationCreate) => {
		return await this.client.post(urls.applications.masterAccountAccess.createLegalPersonAccountApplication, payload);
	};

	submitSignedLegalEntityAccessApplication = async (payload: ApiSignedMasterAccountAccessApplication) => {
		return await this.client.post(urls.applications.masterAccountAccess.createSignedLegalEntityAccountApplication, payload);
	};

	submitLegalEntityAccessFromEmployeeApplication = async (payload: ApiLegalEntityMasterAccountAccessFromEmployeeApplicationCreate) => {
		return await this.client.post(urls.applications.masterAccountAccess.createLegalEntityAccountFromEmployeeApplication, payload);
	};

	submitLegalPersonAccessFromEmployeeApplication = async (payload: ApiLegalPersonMasterAccountAccessFromEmployeeApplicationCreate) => {
		return await this.client.post(urls.applications.masterAccountAccess.createLegalPersonAccountFromEmployeeApplication, payload);
	};

	getLegalEntityAccountApplicationId = async (inn: string, ogrn: string, kpp: string,
		status: MasterAccountAccessApplicationStatus): Promise<boolean> => {
		let { exists } = await this.client.get<{ exists: boolean }>(prepareUrl(urls.applications.masterAccountAccess.getLegalEntityAccountApplicationId,
			{ inn, ogrn, kpp, status }));
		return exists;
	};

	getLegalPersonAccountApplicationId = async (inn: string, ogrnIp: string,
		status: MasterAccountAccessApplicationStatus): Promise<boolean> => {
		let { exists } = await this.client.get<{ exists: boolean }>(prepareUrl(urls.applications.masterAccountAccess.getLegalPersonAccountApplicationId,
			{ inn, ogrnIp, status }));
		return exists;
	};

	getMasterAccountAccessApplication = async (id: string): Promise<ApiMasterAccountAccessApplication> => {
		const url = urls.applications.masterAccountAccess.getApplicationDetails.replace(urlTemplateParts.id, id);

		let data = await this.client.get<ApiMasterAccountAccessApplication>(url);

		return plainToInstance(ApiMasterAccountAccessApplication, data);
	};
}
